import React from 'react';

export const getTsAndCsText = (
  TsAndCsLink?: string,
  organiserName?: string,
) => {
  return (
    <>
      I accept the CrowdClip®{' '}
      <a href="https://my.crowdclip.com/terms" target="_blank" rel="noreferrer">
        T&C
      </a>
      {TsAndCsLink && (
        <>
          {' '}
          and {organiserName || "event organiser's"}{' '}
          <a href={TsAndCsLink} target="_blank" rel="noreferrer">
            T&C
          </a>
        </>
      )}
    </>
  );
};
