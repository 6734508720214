import React, { useState, useEffect, useRef } from 'react';
import auth0 from 'auth0-js';
import moment from 'moment';

import { getEventImg, goTo } from '../../Utils';

import { Button } from '../../components/general';
import { ErrorModal } from '../../components/modals';

import config from '../../config';

import { getEventPublic } from '../../services/api/events';
import {
  checkInFace,
  getFaceWithFiles,
  getFaceWithFilesById,
} from '../../services/checkInService';

import { useAttendeeStore } from '../../stores/attendee';
import withRouter from '../../withRouter';

import CheckInPageUI from './components/checkInPageUI';

const { featureToggles } = config;

const getFaceFromAuth = async (webAuth, event, setBearerToken) => {
  // Check if we can retrieve the face from the user
  return new Promise((resolve, reject) => {
    webAuth.checkSession({}, (err, authResult) => {
      if (err) {
        console.error('check session error', err);
        return resolve({ face: null });
      }

      webAuth.client.userInfo(authResult.accessToken, async (err, user) => {
        if (err) {
          console.error('Get user info error', err);
          return resolve({ face: null });
        }
        setBearerToken(authResult.idToken);
        const { face } = await checkInFace(event, user.email);
        return resolve({ face });
      });
    });
  });
};

const EventCheckIn = (props) => {
  const { eventId, faceId } = props.params;

  const queryParameters = new URLSearchParams(window.location.search);
  const reqShots = queryParameters.get('reqShots');

  const attendeeEmail = useAttendeeStore((state) => state.attendeeEmail);

  const [event, setEvent] = useState({});
  const [bannerUrl, setBannerUrl] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  const face = useAttendeeStore((state) => state.face);
  const setFace = useAttendeeStore((state) => state.setFace);
  const setBearerToken = useAttendeeStore((state) => state.setBearerToken);

  const didMountRef = useRef(false);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const fetchedEvent = await getEventPublic(eventId);

        if (!fetchedEvent) {
          setPageError(404);
          throw new Error('Event data is null or undefined');
        }

        setEvent(fetchedEvent);
        setBannerUrl(getEventImg('banner', fetchedEvent.banner));
        setLogoUrl(getEventImg('logo', fetchedEvent.logo));

        // check event is still ongoing
        if (
          !faceId &&
          (moment(fetchedEvent.endDate).isBefore(moment().utc(), 'day') ||
            fetchedEvent.stage.toLowerCase() !== 'upload')
        ) {
          setError({
            heading: 'Sorry, this event has ended',
            subheading:
              'But you can learn more about CrowdClip&reg; <a href="https://crowdclip.com/">here</a>.',
            onClick: () => goTo('https://crowdclip.com/'),
          });
          return;
        }

        if (face) return;

        if (faceId) {
          const faceResult = await getFaceWithFilesById(
            fetchedEvent.eventId,
            faceId,
          );

          if (!faceResult || !reqShots)
            setError({
              heading: 'Sorry, this link is invalid',
              subheading:
                'But you can learn more about CrowdClip&reg; <a href="https://crowdclip.com/">here</a>.',
              onClick: () => goTo('https://crowdclip.com/'),
            });

          setFace(faceResult);

          return;
        }

        const webAuth = new auth0.WebAuth({
          domain: process.env.REACT_APP_AUTH0_DOMAIN,
          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
          responseType: 'token id_token',
          scope: 'openid profile email',
          redirectUri: `${window.location.origin}/complete-check-in?eventId=${fetchedEvent.eventId}`,
        });

        let { face: faceResult } = featureToggles.auth0
          ? await getFaceFromAuth(webAuth, fetchedEvent, setBearerToken)
          : await checkInFace(fetchedEvent, attendeeEmail);

        if (faceResult) {
          faceResult = await getFaceWithFiles(fetchedEvent.eventId, faceResult);
          setFace(faceResult);
        }
      } catch (error) {
        setError({
          subheading: 'Error fetching some data',
          isTryAgainBtn: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (!didMountRef.current) {
      fetchEventData();
      didMountRef.current = true;
    }
  }, [attendeeEmail, eventId, face, faceId, reqShots, setBearerToken, setFace]);

  const refetchFace = async () => {
    const faceTemp = await getFaceWithFiles(eventId, face, true);
    setFace(faceTemp);
  };

  if (!pageError && error) {
    return (
      <ErrorModal
        show={!!error}
        heading={error?.heading ?? 'Something went wrong'}
        subheading={error?.subheading}
        btn={
          error?.onClick && (
            <Button className="w-full" onClick={error?.onClick}>
              OK :(
            </Button>
          )
        }
        isTryAgainBtn={error?.isTryAgainBtn}
        isContactUs
      />
    );
  }

  return (
    <CheckInPageUI
      width={window.innerWidth}
      isLoading={isLoading}
      pageError={pageError}
      title="Check-In"
      event={event}
      bannerUrl={bannerUrl}
      logoUrl={logoUrl}
      face={face}
      faceId={faceId}
      reqShots={reqShots}
      refetchFace={refetchFace}
    />
  );
};

export default withRouter(EventCheckIn);
