import ReactGA from 'react-ga4';

export const trackHomeClickViewAllProjects = (label: string) => {
  ReactGA.event({
    category: 'User',
    action: 'home_click_view_all_projects',
    label,
  });
};

export const trackHomeProjectsTabChange = (tab: string) => {
  ReactGA.event({
    category: 'User',
    action: 'home_projects_tab_change',
    label: `To ${tab}`,
  });
};

export const trackProjectsTabChange = (tab: string) => {
  ReactGA.event({
    category: 'User',
    action: 'projects_tab_change',
    label: `To ${tab}`,
  });
};

export const trackProjectsFilterChange = (key: string, filter: string) => {
  ReactGA.event({
    category: 'User',
    action: `projects_filter_${key}_change`,
    label: `To ${filter}`,
  });
};

export const trackProjectsFilterClear = () => {
  ReactGA.event({
    category: 'User',
    action: 'projects_filter_clear',
  });
};

export const trackProjectsSortChange = (sort: string) => {
  ReactGA.event({
    category: 'User',
    action: 'projects_sort_change',
    label: `To ${sort}`,
  });
};
