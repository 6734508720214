import React from 'react';
import classNames from 'classnames';

type Props = { label: string; onClick: () => void; isCurrent?: boolean };

const ProjectsTab = (props: Props) => {
  const { label, onClick, isCurrent } = props;

  return (
    <div
      key={label}
      className={classNames(
        'p-2 font-medium capitalize border-b border-solid cursor-pointer transition-all hover:text-black',
        {
          'text-black/[0.5] border-transparent': !isCurrent,
          'text-black border-black': isCurrent,
        },
      )}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default ProjectsTab;
