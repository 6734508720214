import { toast } from 'react-toastify';
import { object, string, array } from 'yup';

import { isEmpty } from '../../../Utils';

import {
  testEmail,
  testConfirmEmail,
  testTsAndCs,
} from '../../../validationUtils';

const getSchema = (type: string, isCheckConfirm?: boolean) => {
  const emailSchema = object({
    email: testEmail(),
    confirmEmail: isCheckConfirm ? testConfirmEmail() : string().notRequired(),
    isTsAndCs: testTsAndCs(),
  });

  const detailsSchema = object({
    name: string().required('First name is required'),
  });

  const photoSchema = object({
    selfieUrl: string().required('Photo is required'),
  });

  const videosSchema = object({
    videoFiles: array().min(1, 'Videos are required'),
  });

  switch (type) {
    case 'detailsPhoto':
      return detailsSchema.concat(photoSchema);
    case 'details':
      return detailsSchema;
    case 'photo':
      return photoSchema;
    case 'video':
      return videosSchema;
    case 'email':
    default:
      return emailSchema;
  }
};

export const validateForm = async (
  type: string,
  formData: {},
  isCheckConfirm?: boolean,
) => {
  const errors: { [key: string]: string } = {};

  const schema = getSchema(type, isCheckConfirm);

  await schema.validate(formData, { abortEarly: false }).catch((err) => {
    err.inner.forEach((error: any) => {
      errors[error.path] = error.errors[0];
    });
  });

  if (!isEmpty(errors)) toast.error('Some data is missing or invalid');

  return errors;
};
