import { getBffClient } from '../client/http';

export const findOneEvent = async (eventId: string) => {
  try {
    const response = await getBffClient().post(
      `/api/mongodb/events/findOne?eventId=${eventId}`,
    );
    return response.data.event;
  } catch (err: any) {
    throw new Error(`Error finding event: ${err.response?.data?.error}`);
  }
};

export const findOneElement = async (props: {
  eventId: string;
  elementId: string;
  elementType: string;
}) => {
  try {
    const { eventId, elementId, elementType } = props;

    let url = `/api/mongodb/elements/findOne?eventId=${eventId}`;
    if (elementId) url += `&elementId=${elementId}`;
    if (elementType) url += `&elementType=${elementType}`;

    const response = await getBffClient().post(url);

    return response.data.element;
  } catch (err: any) {
    throw new Error(`Error finding element: ${err.response?.data?.error}`);
  }
};

export const updateOneEvent = async (props: {
  eventId: string;
  TsAndCsLink: string;
  organiserName: string;
  creationTemplateId: string;
  creationTemplateText: string;
  allowDonations?: boolean;
  paymentStripeId?: string;
  paymentAmount?: number;
}) => {
  try {
    const {
      eventId,
      TsAndCsLink = null,
      organiserName = null,
      creationTemplateId = null,
      creationTemplateText = null,
      allowDonations = null,
      paymentStripeId = null,
      paymentAmount = 0,
    } = props;

    let url = `/api/mongodb/events/updateOne?eventId=${eventId}`;

    if (TsAndCsLink !== null) url += `&TsAndCsLink=${TsAndCsLink}`;
    if (organiserName !== null) url += `&organiserName=${organiserName}`;

    if (creationTemplateId !== null)
      url += `&creationTemplateId=${creationTemplateId}`;
    if (creationTemplateText !== null)
      url += `&creationTemplateText=${creationTemplateText.replace(/\n/g, '<br />')}`;

    if (allowDonations !== null) url += `&allowDonations=${allowDonations}`;
    if (paymentStripeId)
      url += `&paymentStripeId=${paymentStripeId}&paymentAmount=${paymentAmount}`;

    await getBffClient().post(url);
  } catch (err: any) {
    throw new Error(`Error updating event: ${err.response?.data?.error}`);
  }
};

export const setAttendeeReviewData = async (props: {
  eventId: string;
  isAttendeeReview: string;
  releaseDate: string;
  isRemindLater: string;
}) => {
  try {
    const { eventId, isAttendeeReview, releaseDate, isRemindLater } = props;

    let url = `/api/mongodb/events/set-attendee-review-data?eventId=${eventId}`;
    if (isAttendeeReview) url += `&isAttendeeReview=${isAttendeeReview}`;
    if (releaseDate) url += `&releaseDate=${releaseDate}`;
    if (isRemindLater) url += `&isRemindLater=${isRemindLater}`;

    await getBffClient().post(url);
  } catch (err: any) {
    throw new Error(
      `Error updating attendee review data: ${err.response?.data?.error}`,
    );
  }
};

export const setAssigneeData = async (props: {
  eventId: string;
  peopleAssignees: string;
  hlReelAssignees: string;
  clipsAssignees: string;
}) => {
  try {
    const { eventId, peopleAssignees, hlReelAssignees, clipsAssignees } = props;

    let url = `/api/mongodb/events/set-assignee-data?eventId=${eventId}`;
    if (peopleAssignees) url += `&peopleAssignees=${peopleAssignees}`;
    if (hlReelAssignees) url += `&hlReelAssignees=${hlReelAssignees}`;
    if (clipsAssignees) url += `&clipsAssignees=${clipsAssignees}`;

    await getBffClient().post(url);
  } catch (err: any) {
    throw new Error(
      `Error updating assignee data: ${err.response?.data?.error ?? err.message}`,
    );
  }
};

export const updateOneFace = async (props: {
  eventId: string;
  elementId: string;
  email?: string;
  name?: string;
  instagram?: string;
  customFieldData?: string;
  paymentStripeId?: string;
  paymentType?: string;
  paymentAmount?: number;
}) => {
  try {
    const {
      eventId,
      elementId,
      email = null,
      name = null,
      instagram = null,
      customFieldData = null,
      paymentStripeId = null,
      paymentType = '',
      paymentAmount = 0,
    } = props;

    let url = `/api/mongodb/elements/updateOneFace?eventId=${eventId}&elementId=${elementId}`;
    if (email !== null) url += `&email=${email}`;
    if (name !== null) url += `&name=${name}`;
    if (instagram !== null) url += `&instagram=${instagram}`;
    if (customFieldData !== null) url += `&customFieldData=${customFieldData}`;
    if (paymentStripeId)
      url += `&paymentStripeId=${paymentStripeId}&paymentType=${paymentType}&paymentAmount=${paymentAmount}`;

    await getBffClient().post(url);
  } catch (err: any) {
    throw new Error(
      `Error updating face: ${err.response?.data?.error ?? err.message}`,
    );
  }
};
