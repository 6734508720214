import React, { ReactNode, ChangeEventHandler } from 'react';

type Props = {
  id?: string;
  label?: string | ReactNode;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const Switch = (props: Props) => {
  const { id = 'switch', label, checked, onChange } = props;

  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        id={id}
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={checked}
        onChange={onChange}
      />
      <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-gray-300 dark:peer-focus:ring-gray-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-600" />

      {label && (
        <span className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label}
        </span>
      )}
    </label>
  );
};

export default Switch;
