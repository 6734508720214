import React, { useEffect, useState, ChangeEvent, FormEvent } from 'react';

import { isEmpty, getFileImg, getBase64 } from '../../../Utils';
import { UploadInfoPoints } from '../../../components/custom';
import { Form, FormError } from '../../../components/form';
import { Button } from '../../../components/general';
import { ConfirmModal, ErrorModal } from '../../../components/modals';
import { DraggerSimplified } from '../../../components/video';

import { URL } from '../../../constants';

import { checkIn } from '../../../services/checkInService';

import { Event, Face } from '../../../types';
import { validateForm } from './checkInValidations';
import { getDetailsText } from './checkinText';
import DetailsSection from './detailsSection';

interface Props {
  event: Event;
  face: Face;
  isPreview: boolean;
}

const DetailsStage: React.FC<Props> = (props) => {
  const { event, face, isPreview } = props;

  const queryParameters = new URLSearchParams(window.location.search);
  const isNew = queryParameters.get('isNew') === 'true';

  const [personData, setPersonData] = useState({
    name: isNew ? '' : face.name ?? '',
    instagram: face.instagram ?? '',
    customFieldData: face.customFieldData ?? '',
  });

  const [selfieFile, setSelfieFile] = useState<any>(null);
  const [selfieUrl, setSelfieUrl] = useState<string>(
    face.photos?.[0] ? getFileImg(face.photos[0]) : '',
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (isNew)
      window.history.replaceState(null, '', `${URL.CHECK_IN}${event.eventId}`);
  }, [event.eventId, isNew]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPersonData((prevData) => ({ ...prevData, [name]: value }));

    setFormErrors({});
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isPreview) return;

    const validationErrors = await validateForm('detailsPhoto', {
      ...personData,
      selfieUrl,
    });

    // if errors found – set errors and stop here
    if (!isEmpty(validationErrors)) {
      setFormErrors(validationErrors);
      return;
    }

    setIsProcessing(true);
    setShowConfirmModal(true);

    try {
      await checkIn({
        eventId: event.eventId,
        faceData: { elementId: face.elementId, ...personData },
        photo: selfieFile,
      });
    } catch (err: any) {
      setShowConfirmModal(false);
      setError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const changeSelfie = (file: File) => {
    getBase64(file, (url: string) => setSelfieUrl(url));
    setSelfieFile(file);

    setFormErrors({});
  };

  const { selfieInfoPoints, tipsList } = getDetailsText();

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <h5>
          <b>Step 1</b>: Fill in your details
        </h5>

        <DetailsSection
          event={event}
          data={personData}
          onChange={handleChange}
          errors={formErrors}
        />

        <hr className="my-4" />

        <h5>
          <b>Step 2</b>: Upload a photo or a selfie*
        </h5>

        <div>
          <DraggerSimplified
            id="ci-photo-upload"
            type="image"
            heading="Photo upload (1 max)"
            imgUrl={selfieUrl}
            onDrop={changeSelfie}
            isPreview={isPreview}
          />

          <FormError errMsg={formErrors.selfieUrl} />
        </div>

        <UploadInfoPoints points={selfieInfoPoints} />

        <Button isSubmitBtn isProcessing={isProcessing}>
          Submit
        </Button>
      </Form>

      <ConfirmModal
        show={showConfirmModal}
        isProcessing={isProcessing}
        heading={
          isProcessing
            ? "We're updating your details"
            : `Great! You have successfully checked in to ${event.name}, ${personData.name}.`
        }
        subheading={
          !isProcessing &&
          'Feel free to <b>close this page</b>, and keep an eye on your inbox for your personalised video.'
        }
        tipsList={tipsList}
      />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        btn={
          <Button onClick={() => setError(null)} className="w-full">
            OK
          </Button>
        }
      />
    </React.Fragment>
  );
};

export default DetailsStage;
