import React, { useState } from 'react';
import classNames from 'classnames';
import {
  PiDeviceMobile,
  PiXCircle,
  PiXCircleFill,
  PiCaretLeftBold,
  PiCaretRightBold,
} from 'react-icons/pi';
import { toast } from 'react-toastify';

import { isEmpty } from '../../../Utils';

import {
  trackUpdateProjectSuccess,
  trackUpdateProjectError,
} from '../../../analytics';

import { Form } from '../../../components/form';
import { Button } from '../../../components/general';
import { EVENT_TYPE_BRAND } from '../../../constants';

import { mergeEvent } from '../../../services/api/events';
import { updateOneEvent } from '../../../services/api/mongodb';

import { useEventStore } from '../../../stores/event';

import HeadingWithInfo from './headingWithInfo';
import { validateForm } from './projectSettingsValidations';

const TabComponent = (props) => {
  const { title, subtitle, blocks, preview, event, data, isBrand, setErrors } =
    props;

  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [isSaving, setIsSaving] = useState(false);

  const isBrandUpd = isBrand ?? event.type === EVENT_TYPE_BRAND;

  const onSubmit = async (e) => {
    if (e) e.preventDefault();

    setIsSaving(true);

    const {
      eventName = null,
      type = null,
      bannerFile = null,
      logoFile = null,
      TsAndCsLink = null,
      organiserName = null,
      customFieldLabel = null,
      caption = null,
      hashtagArray = null,
      allowDonations = null,
      startDate = null,
      endDate = null,
      orientation = null,
      wildcards = null,
    } = data;

    let errors = null;

    if (setErrors)
      errors = await validateForm(isBrandUpd, {
        type,
        eventName,
        startDate,
        endDate,
        orientation,
        wildcards,
      });

    if (!isEmpty(errors)) {
      setErrors(errors);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      toast.error('Some data is missing or invalid');
      setIsSaving(false);
      return;
    }

    // if no errors – save data

    const formData = new FormData();

    formData.append('eventId', event.eventId);
    formData.append('userId', event.userId);
    formData.append('created', event.created);
    formData.append('stage', event.stage);

    formData.append('name', eventName ?? event.name);
    formData.append('type', type ?? event.type);
    formData.append('videoMode', orientation ?? event.videoMode ?? '');

    const hasCustomBanner = !!bannerFile;
    formData.append('hasCustomBanner', hasCustomBanner);
    if (hasCustomBanner) formData.append('banner', bannerFile);

    const hasCustomLogo = !!logoFile;
    formData.append('hasCustomLogo', hasCustomLogo);
    if (hasCustomLogo) formData.append('logo', logoFile);

    formData.append('caption', caption ?? event.caption ?? '');
    (hashtagArray ?? event.hashTag ?? []).forEach((ht) =>
      formData.append('hashTag[]', ht),
    );
    formData.append(
      'customFieldLabel',
      customFieldLabel ?? event.customFieldLabel ?? '',
    );

    if (isBrandUpd) {
      formData.append('startDate', startDate ?? event.startDate);
      formData.append('endDate', endDate ?? event.endDate);

      (wildcards ?? event.wildcards).forEach((w) => {
        formData.append('wildcards[]', parseFloat(w));
      });
    }

    try {
      const event = await mergeEvent(formData);
      await updateOneEvent({
        eventId: event.eventId,
        TsAndCsLink,
        organiserName,
        allowDonations,
      });

      await updateCurrentEvent();

      trackUpdateProjectSuccess();
      toast.success('Project is updated');
    } catch {
      trackUpdateProjectError();
      toast.error('Something went wrong. Try again later');
    } finally {
      setIsSaving(false);
    }

    // session storage cleanup
    sessionStorage.removeItem('attendees');
  };

  const previewContainerClass = classNames({
    'w-[75%] aspect-[1/2]': true,
    'max-h-[calc(100vh_-_var(--header-height)_-_(var(--content-vertical-spacing)_*_2)_-_1rem_-_(1.125rem_*_1.5))]':
      !preview?.note,
    'max-h-[calc(100vh_-_var(--header-height)_-_(var(--content-vertical-spacing)_*_2)_-_(1rem_*_2)_-_((1.125rem_+_0.875rem)_*_1.5))]':
      preview?.note,
  });

  const previewContentClass = classNames({
    'absolute top-[calc(1.125rem_*_1.5_+_1rem)] left-0 w-full aspect-[1/2] scale-75 origin-top rounded-3xl shadow-grey-2 overflow-scroll': true,
    'max-h-[calc((100vh_-_var(--header-height)_-_(var(--content-vertical-spacing)_*_2)_-_1rem_-_(1.125rem_*_1.5))_/_0.75)]':
      !preview?.note,
    'max-h-[calc((100vh_-_var(--header-height)_-_(var(--content-vertical-spacing)_*_2)_-_(1rem_*_2)_-_((1.125rem_+_0.875rem)_*_1.5))_/_0.75)]':
      preview?.note,
  });

  const caretBtnClass =
    'absolute top-[50%] w-8 aspect-square flex items-center justify-center p-2 bg-black text-white opacity-20 hover:opacity-40 rounded-full cursor-pointer';

  return (
    <div className="flex gap-10">
      <div
        className={classNames(
          'w-full flex flex-col gap-6 sm:gap-8 transition-all',
          { 'lg:w-[58%]': preview?.show },
        )}
      >
        {(title || subtitle) && (
          <div className="flex flex-col gap-2">
            {title && (
              <div className="flex items-center justify-between">
                <h3 className="font-semibold leading-[2.5rem]">{title}</h3>

                {preview && !preview.show && (
                  <Button
                    onClick={preview.onShow}
                    variant="black-outline"
                    className="hidden lg:flex text-[1.25rem]"
                    isThin
                    withIcon
                  >
                    <PiDeviceMobile />
                    <div className="text-sm font-bold">Preview</div>
                  </Button>
                )}
              </div>
            )}

            {subtitle && <div>{subtitle}</div>}
          </div>
        )}

        <Form onSubmit={onSubmit} disabled={isSaving} className="gap-8">
          {blocks.map(
            (block) =>
              !block.isHide && (
                <div
                  key={block.title}
                  className="flex flex-col p-4 gap-4 rounded-md border border-grey-200 border-solid"
                >
                  <div className="flex flex-col gap-2">
                    <HeadingWithInfo
                      subheading={block.title}
                      infoHeader={block.infoHeader}
                      infoBody={block.infoBody}
                    />

                    {block.description && (
                      <div className="text-sm text-black/[0.5]">
                        {block.description}
                      </div>
                    )}
                  </div>

                  {block.content}
                </div>
              ),
          )}

          <Button type="submit" isSubmitBtn isProcessing={isSaving}>
            Save
          </Button>
        </Form>
      </div>

      {preview?.show && (
        <div className="hidden lg:block w-[42%]">
          <div className="sticky top-[calc(var(--header-height)_+_var(--content-vertical-spacing))] w-full flex flex-col items-center gap-4">
            <div className="relative w-[75%] flex items-center justify-between">
              <div className="text-lg font-bold">Preview</div>

              <div
                className="group w-6 h-6 text-xl leading-none cursor-pointer"
                onClick={preview.onHide}
              >
                <PiXCircle className="absolute opacity-100 group-hover:opacity-0" />
                <PiXCircleFill className="absolute opacity-0 group-hover:opacity-100" />
              </div>
            </div>

            <div className={previewContainerClass}>
              {preview.onClickLeft && (
                <div
                  className={classNames(caretBtnClass, 'left-[2%]')}
                  onClick={preview.onClickLeft}
                >
                  <PiCaretLeftBold />
                </div>
              )}

              <div ref={preview.previewRef} className={previewContentClass}>
                {preview.content}
              </div>

              {preview.onClickRight && (
                <div
                  className={classNames(caretBtnClass, 'right-[2%]')}
                  onClick={preview.onClickRight}
                >
                  <PiCaretRightBold />
                </div>
              )}
            </div>

            {preview.note && (
              <div className="text-sm italic text-center">{preview.note}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TabComponent;
