import React, { useState, useEffect, useMemo } from 'react';
import {
  FolderOpen,
  UsersThree,
  Slideshow,
  MonitorArrowUp,
  Database,
  Video,
  VideoCamera,
  Play,
  HandPointing,
  CurrencyDollar,
} from '@phosphor-icons/react';
import { Link, useParams } from 'react-router-dom';

import { isEmpty, getPct, getDuration } from '../../Utils';

import { Alert } from '../../components/general';
import { PageSpinner, ContentHeader } from '../../components/layout/content';
import { ErrorModal } from '../../components/modals';

import { URL } from '../../constants';

import { getCreationResults } from '../../services/api/creations';
import { getUploadedFaces } from '../../services/api/faces';
import { getTemplateFile } from '../../services/api/template';
import { getUploadedSelects } from '../../services/selectGroupsService';

import { useEventStore } from '../../stores/event';

import { File, UploadedPerson, GeneratedPerson } from '../../types';

import { calculateClipsStats, calculateKeyStats } from './components/getStats';
import StatsWithListBlock from './components/statsWithListBlock';

const ProjectAnalytics = () => {
  const { eventId } = useParams();

  const { eventId: eventIdLocal, setEventId, currentEvent } = useEventStore();

  const [uploadedPeople, setUploadedPeople] = useState<UploadedPerson[]>([]);
  const [generatedPeople, setGeneratedPeople] = useState<GeneratedPerson[]>([]);
  const [uploadedClips, setUploadedClips] = useState<File[]>([]);
  const [hlReel, setHlReel] = useState<File>({});

  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState<string | null>(null);

  const { isPayments, isDonation } = useMemo(() => {
    const paymentObj = currentEvent?.payment ?? {};

    return {
      isPayments: !paymentObj.paid,
      isDonation: paymentObj.paid && (paymentObj.allowDonations ?? true),
    };
  }, [currentEvent]);

  // set event id on mount
  useEffect(() => {
    if (eventIdLocal !== eventId) setEventId(eventId);
  }, [eventId, eventIdLocal, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (currentEvent?.error) setPageError(currentEvent.error);
      else if (currentEvent) {
        try {
          setHlReel(await getTemplateFile(eventId));
          setUploadedClips(await getUploadedSelects(eventId));
          setUploadedPeople(await getUploadedFaces(eventId));
          setGeneratedPeople(await getCreationResults(eventId));
        } catch {
          setError('Error fetching data');
        }
      }

      setIsLoading(false);
    };

    if (currentEvent) onMount();
  }, [currentEvent]);

  const { clipsSize, clipsDuration } = useMemo(
    () => calculateClipsStats(uploadedClips),
    [uploadedClips],
  );

  const {
    fpDataCount,
    playsTotal,
    topEngPeople,
    engPct,
    payees,
    paymentSum,
    paymentStyles,
  } = useMemo(
    () => calculateKeyStats(generatedPeople, hlReel, isDonation),
    [generatedPeople, hlReel, isDonation],
  );

  const iconClass = "w-16 h-16 fill-[url('#icon-primary-gradient')]";

  const keyData = [
    {
      heading: 'Data Gathered',
      blocks: [
        {
          icon: <FolderOpen weight="fill" className={iconClass} />,
          num:
            uploadedPeople.length +
            uploadedClips.length +
            (!isEmpty(hlReel) ? 1 : 0),
          label: 'Files Ingested',
          show: true,
        },
        {
          icon: <UsersThree weight="fill" className={iconClass} />,
          num: uploadedPeople.length,
          label: 'People Added',
          show: true,
        },
        {
          icon: <Slideshow weight="fill" className={iconClass} />,
          num:
            getDuration(hlReel?.parameters?.length || 0, false, true) ||
            '0 min',
          label: 'Highlight Reel Uploaded',
          show: true,
        },
        {
          icon: <MonitorArrowUp weight="fill" className={iconClass} />,
          num: `${clipsDuration} (${clipsSize} GB)`,
          label: 'Footage Ingested',
          show: true,
        },
        {
          icon: <Database weight="fill" className={iconClass} />,
          num: fpDataCount,
          label: 'First-Party Data Captured',
          show: true,
        },
      ],
    },
    {
      heading: 'Outcomes Achieved',
      blocks: [
        {
          icon: <Video weight="fill" className={iconClass} />,
          num: `${getPct(generatedPeople.length / uploadedPeople.length) || 0}%`,
          label: 'Videos Captured',
          show: true,
        },
        {
          icon: <VideoCamera weight="fill" className={iconClass} />,
          num: generatedPeople.length,
          label: 'Videos Created',
          show: true,
        },
        {
          icon: <Play weight="fill" className={iconClass} />,
          num: getDuration(playsTotal, true),
          label: 'New Assets Played',
          show: true,
        },
        {
          icon: <HandPointing weight="fill" className={iconClass} />,
          num: `${engPct}%`,
          label: 'Videos Engaged',
          show: true,
        },
        {
          icon: <CurrencyDollar weight="fill" className={iconClass} />,
          num: `${getPct(payees.length / generatedPeople.length) || 0}%`,
          label: 'Attendees Paid',
          show: isPayments,
        },
        {
          icon: <CurrencyDollar weight="fill" className={iconClass} />,
          num: `$${paymentSum}`,
          label: 'Donations Raised',
          show: isDonation,
        },
      ],
    },
  ];

  return (
    <PageSpinner
      title={`Project Analytics – ${currentEvent?.name}`}
      isLoading={isLoading}
      pageError={pageError}
      className="h-full flex gap-6 sm:gap-10"
      isPageContainer
    >
      <ContentHeader title="Project Analytics" />

      {isEmpty(generatedPeople) ? (
        <Alert
          alertData={{
            variant: 'warning',
            text: (
              <>
                Hmm, seems like you haven't completed the project yet. Analytics
                will be available as soon as you complete all{' '}
                <Link to={`/${eventId}${URL.VIDEO_BUILDER}`}>
                  Video Builder
                </Link>{' '}
                steps.
              </>
            ),
          }}
        />
      ) : (
        <React.Fragment>
          <div className="flex flex-col p-4 gap-8 rounded-md border border-grey-200 border-solid">
            {keyData.map((data) => (
              <div key={data.heading} className="flex flex-wrap gap-4 sm:gap-8">
                <div className="w-full sm:w-[12%] self-center text-center text-xl font-bold">
                  {data.heading}
                </div>

                {data.blocks.map(
                  (block) =>
                    block.show && (
                      <div
                        key={block.label}
                        className="w-[calc((100%_-_1rem)_/_2)] sm:w-[calc((88%_-_(2rem_*_5))_/_5)] flex flex-col items-center text-center"
                      >
                        {block.icon}

                        <div className="text-lg font-bold text-primary-900">
                          {block.num}
                        </div>

                        <div className="text-sm">{block.label}</div>
                      </div>
                    ),
                )}
              </div>
            ))}
          </div>

          <StatsWithListBlock
            heading="Engagement"
            currentEvent={currentEvent}
            generatedPeople={generatedPeople}
            statsBlockShowPages={['played', 'downloaded', 'shared']}
            listHeading="Most engaged attendees"
            listEmptyText="No engagements made"
            list={topEngPeople}
            listClass="sm:h-[calc((0.25rem_*_4)_+_(0.875rem_*_1.5_*_5))]"
            itemClass="w-full sm:w-[calc((100%_-_2rem)_/_2)]"
          />

          {(isPayments || isDonation) && (
            <StatsWithListBlock
              heading={isPayments ? 'Remove Watermark Payments' : 'Donations'}
              currentEvent={currentEvent}
              generatedPeople={generatedPeople}
              statsBlockShowPages={['payment']}
              listHeading="List of payments"
              listEmptyText="No payments made"
              list={payees}
              listStyle={paymentStyles.payeesContainer}
              itemStyle={paymentStyles.payeeBox}
            />
          )}
        </React.Fragment>
      )}

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
      />
    </PageSpinner>
  );
};

export default ProjectAnalytics;
