import React, { FC, useMemo, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import {
  PiQuestion,
  PiVideo,
  PiFileZip,
  PiCalendarBlank,
} from 'react-icons/pi';
import { Link, useNavigate } from 'react-router-dom';

import { isEmpty, toTitleCase } from '../../../Utils';

import {
  trackUGModalOpen,
  trackOnboardingModalOpen,
  trackQuickstartPackDownload,
  trackSampleProjectOpen,
  trackHomeClickViewAllProjects,
  trackHomeProjectsTabChange,
} from '../../../analytics';

import { Button } from '../../../components/general';
import { PageContainer } from '../../../components/layout/content';

import { URL, DEMO_ID, EVENT_TYPE_DEMO, ZIP_URL } from '../../../constants';

import { useEventStore } from '../../../stores/event';
import { useUserStore } from '../../../stores/user';
import { Event } from '../../../types';

import { demoFifa } from '../../VideoBuilder/components/demoData';

import ProjectsContainer from '../components/projectsContainer';
import ProjectsTab from '../components/projectsTab';
import CreateProjectBtn from './components/createProjectBtn';
import OnboardingVideoModal from './components/onboardingVideoModal';
import UserGuidingModal from './components/userGuidingModal';

const Home: FC = () => {
  const user = useUserStore((state) => state.user);
  const userEvents: Event[] = useEventStore((state) => state.userEvents);
  const setEventId = useEventStore((state) => state.setEventId);

  const [pageTab, setPageTab] = useState('recent');
  const [showOnboardingVideo, setShowOnboardingVideo] = useState(false);
  const [showUGModal, setShowUGModal] = useState(false);

  const navigate = useNavigate();

  const { isNewUser, recentProjects, completedProjects } = useMemo(() => {
    let isNewUser = false;
    const recentProjects: Event[] = [];
    const completedProjects: Event[] = [];

    userEvents.forEach((event) => {
      if (event.type !== EVENT_TYPE_DEMO) {
        if (event.stage === 'generation') completedProjects.push(event);
        else recentProjects.push(event);
      }
    });

    if (!completedProjects.length && !recentProjects.length) isNewUser = true;

    return { isNewUser, recentProjects, completedProjects };
  }, [userEvents]);

  useEffect(() => {
    setEventId(null);
  }, [setEventId]);

  useEffect(() => {
    if (isNewUser) setShowUGModal(true);
  }, [isNewUser]);

  const handleOpenSampleProject = () => {
    const demoData = localStorage.getItem('demoData');
    if (!demoData) localStorage.setItem('demoData', JSON.stringify(demoFifa));

    trackSampleProjectOpen();

    navigate(`/${DEMO_ID.FIFA}${URL.VIDEO_BUILDER}`);
  };

  const iconClass = 'w-8 h-8 sm:w-10 sm:h-10';

  const learningBlocks = [
    {
      image:
        'https://t3.ftcdn.net/jpg/05/24/43/88/360_F_524438877_PZFzwc5OWJ3MTWQVFfHKwu1DRVMaSgPx.jpg',
      icon: <PiQuestion className={iconClass} />,
      label: 'How does it work?',
      onClick: () => {
        setShowUGModal(true);
        trackUGModalOpen();
      },
    },
    {
      image:
        'https://cdn.pixabay.com/photo/2022/09/21/17/02/blue-background-7470781_640.jpg',
      icon: <PiVideo className={iconClass} />,
      label: 'Watch onboarding video',
      onClick: () => {
        setShowOnboardingVideo(true);
        trackOnboardingModalOpen();
      },
    },
    {
      image:
        'https://st4.depositphotos.com/1741969/29457/i/450/depositphotos_294571810-stock-photo-blackground-of-abstract-glitter-lights.jpg',
      icon: <PiFileZip className={iconClass} />,
      label: 'Download the quickstart pack',
      onClick: () => {
        window.location.href = ZIP_URL.QUICKSTART;
        trackQuickstartPackDownload('Learning materials');
      },
    },
    {
      image: '/static/media/afl-event-thumbnail.79e329cc90e61b383f77.jpeg',
      icon: <PiCalendarBlank className={iconClass} />,
      label: 'Play with Sample Project',
      onClick: () => handleOpenSampleProject(),
    },
  ];

  const tabs = [
    {
      name: 'recent',
      projects: recentProjects,
      emptyMsg: 'Your projects will appear here after creating one',
    },
    {
      name: 'completed',
      projects: completedProjects,
      emptyMsg: 'Completed projects will appear here after generating videos',
    },
  ];

  const selectedTab = useMemo(() => {
    return tabs.find((tab) => tab.name === pageTab);
  }, [pageTab]);

  const MAX_PROJECTS = 10;

  const renderLearningMaterials = () => {
    return (
      <div className="w-full flex flex-col gap-4">
        <h5>Don't know where to start?</h5>

        <div className="flex flex-wrap gap-4 lg:gap-7">
          {learningBlocks.map((block) => {
            const thumbnailClass =
              'w-[var(--event-block-width)] xl:w-[calc((var(--page-width)_-_(1.5rem_*_5))_/_6)] aspect-[1/0.9] rounded-md';

            return (
              <button
                className="group relative"
                onClick={block.onClick}
                key={block.label}
              >
                <img
                  className={`${thumbnailClass} relative object-cover`}
                  src={block.image}
                  alt="Learning block"
                />

                <div
                  className={`${thumbnailClass} absolute top-0 left-0 bg-black-overlay-500 transition-all group-hover:bg-black`}
                />

                <div
                  className={`${thumbnailClass} absolute top-0 left-0 flex flex-col items-center justify-center text-center p-2 md:p-4 gap-2 text-white transition-all group-hover:scale-110`}
                >
                  {block.icon}

                  <div className="text-md sm:text-sm xl:text-md">
                    {block.label}
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div>
        <div className="flex items-center gap-4">
          {tabs.map((tab) => (
            <ProjectsTab
              label={`${tab.name} projects`}
              onClick={() => {
                setPageTab(tab.name);
                trackHomeProjectsTabChange(tab.name);
              }}
              isCurrent={pageTab === tab.name}
            />
          ))}

          <Link
            to={URL.DASH_PROJECTS}
            onClick={() => trackHomeClickViewAllProjects('Main')}
            className="text-sm no-underline"
          >
            View All
          </Link>
        </div>

        {!isEmpty(selectedTab?.projects) ? (
          <ProjectsContainer
            heading={toTitleCase(`${selectedTab?.name} Projects`)}
            events={selectedTab?.projects ?? []}
            projectNumLimit={MAX_PROJECTS}
            hideHeading
          />
        ) : (
          <div className="mt-4 text-sm text-grey-900">
            {selectedTab?.emptyMsg ?? ''}
          </div>
        )}
      </div>
    );
  };

  return (
    <PageContainer title="Home" addClass="gap-8">
      {/* welcome box */}
      <div className="animated-box-purple w-full flex flex-col items-center justify-center text-center p-4 sm:p-6 gap-4 sm:gap-6 text-white rounded-md animate-running-5">
        <div className="text-xl sm:text-2xl font-bold uppercase">
          Welcome {isNewUser ? parse('to CrowdClip&reg;') : 'back'},{' '}
          {user.firstName ?? 'amazing human'}!
        </div>

        <div>
          Let's get started on a better way to generate hundreds of personalised
          videos for your events.
        </div>

        <CreateProjectBtn />

        <Button
          onClick={handleOpenSampleProject}
          variant="link"
          className="text-white"
        >
          Play with Sample Project
        </Button>
      </div>

      {/* content */}
      {isNewUser ? (
        <React.Fragment>
          {renderLearningMaterials()}

          <h5>Projects</h5>

          <div className="w-full text-grey-900 font-bold text-center">
            Your projects will appear here after creating one
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {renderContent()}

          {renderLearningMaterials()}
        </React.Fragment>
      )}

      <OnboardingVideoModal
        isNewUser={false}
        showOnboardingVideo={showOnboardingVideo}
        setShowOnboardingVideo={setShowOnboardingVideo}
      />

      <UserGuidingModal
        show={showUGModal}
        onHide={() => setShowUGModal(false)}
      />
    </PageContainer>
  );
};

export default Home;
