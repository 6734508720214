import React from 'react';
import { WarningCircle } from '@phosphor-icons/react';
import classNames from 'classnames';

import { FormError } from './';

type Props = {
  label: string;
  type?: string;
  as?: string;
  name: string;
  value: string;
  onChange?: any;
  isInvalid?: boolean;
  errMsg?: string;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
  customHeight?: string;
};

const CustomFloatingLabel = (props: Props) => {
  const {
    label,
    type,
    as,
    name,
    value,
    onChange,
    isInvalid,
    errMsg,
    placeholder,
    required,
    readOnly,
    disabled,
    className,
    customHeight,
  } = props;

  const inputClass = classNames(
    'peer block w-full pb-3 text-md leading-5 text-gray-900 bg-true-white rounded-md border appearance-none duration-300 focus:outline-none focus:ring-0 focus:border-blue-600 disabled:bg-grey-200 disabled:pointer-events-none read-only:bg-grey-200 read-only:pointer-events-none',
    {
      'pt-6': !!label,
      'pt-3': !label,
      'px-3 border-grey-200': !isInvalid,
      'pl-3 pr-[2.625rem] border-error-900 focus:border-error-900': isInvalid,
      'h-[var(--form-el-height)]': as !== 'textarea' && !customHeight,
    },
    customHeight,
  );

  const labelClass = classNames(
    'absolute start-2 px-1 text-md bg-true-white duration-300 transform origin-[0] rounded-md z-10 pointer-events-none duration-300 peer-disabled:bg-grey-200 peer-read-only:bg-grey-200',
    // no value entered
    'peer-placeholder-shown:text-gray-700 peer-placeholder-shown:top-[calc((var(--form-el-height)_-_1.5rem)_/_2)] peer-placeholder-shown:scale-100',
    // general styles – value entered but input not in focus
    'text-black/[0.5] top-1 scale-75 ',
    // focus is on input – should be similar to general styles
    'peer-focus:text-black/[0.5] peer-focus:top-1 peer-focus:scale-75',
    // rtl
    'rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto',
    { 'peer-placeholder-shown:text-black/[0.5]': disabled },
  );

  return (
    <div className={className}>
      <div className="relative">
        {as === 'textarea' ? (
          <textarea
            id="floating_input"
            aria-label={label}
            name={name}
            value={value}
            onChange={onChange}
            rows={5}
            placeholder={placeholder ?? ' '}
            className={inputClass}
            required={required}
            disabled={disabled}
          />
        ) : (
          <input
            id="floating_input"
            aria-label={label}
            type={type ?? 'text'}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder ?? ' '}
            className={inputClass}
            required={required}
            readOnly={readOnly}
            disabled={disabled}
          />
        )}

        {label && (
          <label htmlFor="floating_input" className={labelClass}>
            {label}
            {required && <span className="text-primary-900">*</span>}
          </label>
        )}

        {isInvalid && (
          <div className="absolute top-0 right-3 h-full flex items-center justify-center">
            <WarningCircle size={18} color="var(--error-color)" weight="bold" />
          </div>
        )}
      </div>

      <FormError errMsg={errMsg} />
    </div>
  );
};

export default CustomFloatingLabel;
