import React, { useState, ChangeEvent, FormEvent } from 'react';

import { isEmpty, getBase64 } from '../../../Utils';

import { Form, FormError } from '../../../components/form';
import { Button, Modal } from '../../../components/general';
import { DraggerSimplified } from '../../../components/video';

import { BRAND_CI_STEPS } from '../../../constants';

import { checkIn } from '../../../services/checkInService';

import { Event, Face } from '../../../types';
import { validateForm } from './checkInValidations';
import DetailsSection from './detailsSection';

interface Props {
  show: boolean;
  onHide: () => void;
  event: Event;
  face: Face;
  isProcessing: boolean;
  handleDetailsSteps: (step: string, msg?: string) => void;
  isNew?: boolean;
}

const DetailsModal: React.FC<Props> = (props) => {
  const { show, onHide, event, face, isProcessing, handleDetailsSteps, isNew } =
    props;

  const [isPhotoView, setIsPhotoView] = useState(false);

  const [personData, setPersonData] = useState({
    name: isNew ? '' : face.name ?? '',
    instagram: face.instagram ?? '',
    customFieldData: face.customFieldData ?? '',
  });

  const [selfieFile, setSelfieFile] = useState<any>(null);
  const [selfieUrl, setSelfieUrl] = useState('');

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPersonData((prevData) => ({ ...prevData, [name]: value }));

    setFormErrors({});
  };

  const handleSubmitDetails = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validationErrors = await validateForm('details', personData);

    // if errors found – set errors and stop here
    if (!isEmpty(validationErrors)) {
      setFormErrors(validationErrors);
      return;
    }

    try {
      const faceData = { elementId: face.elementId, ...personData };

      // update face details, and handle before and after actions
      handleDetailsSteps(BRAND_CI_STEPS.DETAILS_STARTED);
      await checkIn({ eventId: event.eventId, faceData });
      handleDetailsSteps(BRAND_CI_STEPS.DETAILS_ENDED);

      // if no photos uploaded – go to photo view
      if (isEmpty(face.photos)) setIsPhotoView(true);
      // else – handle no photo actions
      else {
        // hide details modal (as confirm modal will appear)
        onHide();
        handleDetailsSteps(BRAND_CI_STEPS.NO_PHOTO);
      }
    } catch (err: any) {
      // handle error actions
      handleDetailsSteps(BRAND_CI_STEPS.ERROR, err.message);
    }
  };

  const handleSubmitPhoto = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validationErrors = await validateForm('photo', { selfieUrl });

    // if errors found – set errors and stop here
    if (!isEmpty(validationErrors)) {
      setFormErrors(validationErrors);
      return;
    }

    try {
      // hide details modal (as confirm modal will appear)
      onHide();

      // upload photo, and handle before and after actions
      handleDetailsSteps(BRAND_CI_STEPS.PHOTO_STARTED);
      await checkIn({
        eventId: event.eventId,
        photo: selfieFile,
        faceId: face.elementId,
      });
      handleDetailsSteps(BRAND_CI_STEPS.PHOTO_ENDED);

      // set view back to details
      setIsPhotoView(false);
    } catch (err: any) {
      // handle error actions
      handleDetailsSteps(BRAND_CI_STEPS.ERROR, err.message);
    }
  };

  const changeSelfie = (file: File) => {
    getBase64(file, (url: string) => setSelfieUrl(url));
    setSelfieFile(file);

    setFormErrors({});
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        {!isPhotoView ? (
          <Form onSubmit={handleSubmitDetails}>
            <h5>Fill in your details</h5>

            <DetailsSection
              event={event}
              data={personData}
              onChange={handleChange}
              errors={formErrors}
            />

            <Button isSubmitBtn isProcessing={isProcessing}>
              Submit
            </Button>
          </Form>
        ) : (
          <Form onSubmit={handleSubmitPhoto}>
            <h5>Upload a photo or a selfie</h5>

            <div>
              <DraggerSimplified
                type="image"
                heading="Photo upload (1 max)"
                imgUrl={selfieUrl}
                onDrop={changeSelfie}
              />

              <FormError errMsg={formErrors.selfieUrl} />
            </div>

            <Button isSubmitBtn isProcessing={isProcessing}>
              Upload
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DetailsModal;
