import React, { useState, useEffect, useRef } from 'react';
import auth0 from 'auth0-js';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { PageSpinner } from '../../../components/layout/content';
import { ErrorModal } from '../../../components/modals';

import config from '../../../config';
import { URL } from '../../../constants';

import { getEventPublic } from '../../../services/api/events';
import {
  checkInFace,
  getFaceWithFiles,
} from '../../../services/checkInService';

import { useAttendeeStore } from '../../../stores/attendee';

const CheckinStage = () => {
  const didMount = useRef(false);
  const [searchParams] = useSearchParams();

  const [eventId, setEventId] = useState(searchParams.get('eventId'));

  const attendeeEmail = useAttendeeStore((state) => state.attendeeEmail);

  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const setFace = useAttendeeStore((state) => state.setFace);
  const setBearerToken = useAttendeeStore((state) => state.setBearerToken);
  const { featureToggles } = config;

  useEffect(() => {
    setEventId(searchParams.get('eventId'));
  }, [searchParams]);

  useEffect(() => {
    async function getFace() {
      if (didMount.current || !eventId) {
        return;
      }
      didMount.current = true;

      const fetchedEvent = await getEventPublic(eventId);

      // auth flow
      if (featureToggles.auth0) {
        const webAuth = new auth0.WebAuth({
          domain: process.env.REACT_APP_AUTH0_DOMAIN,
          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
          responseType: 'token id_token',
          state: eventId,
        });

        webAuth.parseHash(
          {
            hash: window.location.hash,
            state: eventId,
            nonce: 'nonce',
          },
          (err, authResult) => {
            if (err) setError(err.error);

            if (authResult && authResult.accessToken)
              webAuth.client.userInfo(
                authResult.accessToken,
                async (err, user) => {
                  if (err) setError(err.error);

                  if (user) {
                    setBearerToken(authResult.idToken);

                    await checkInAndNavigate(fetchedEvent, user.email);
                  }
                },
              );
          },
        );
      }

      // no auth flow
      else await checkInAndNavigate(fetchedEvent, attendeeEmail);
    }

    getFace();
  }, [attendeeEmail, eventId]);

  const checkInAndNavigate = async (event, email) => {
    try {
      let { face, isNew } = await checkInFace(event, email);

      if (face) {
        face = await getFaceWithFiles(eventId, face);
        setFace(face);
      }

      navigate(`${URL.CHECK_IN}${eventId}${isNew ? '?isNew=true' : ''}`);
    } catch (err) {
      setError(err.message);
    }
  };

  return error ? (
    <ErrorModal
      show={!!error}
      heading="Something went wrong"
      subheading={error}
      isTryAgainBtn
    />
  ) : (
    <PageSpinner isLoading noTips isFullPage />
  );
};

export default CheckinStage;
