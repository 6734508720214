import React, { useState, ChangeEvent, FormEvent } from 'react';
import auth0 from 'auth0-js';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from '../../../Utils';
import { Form, FloatingLabel, FormError } from '../../../components/form';
import { Button, Checkbox } from '../../../components/general';

import config from '../../../config';

import { useAttendeeStore } from '../../../stores/attendee';

import { Event } from '../../../types';
import { getTsAndCsText } from './TsAndCsText';
import { validateForm } from './checkInValidations';

const { featureToggles, auth0Domain, auth0ClientID } = config;

const authenticate = (webAuth: any, eventId: string, email: string) => {
  return new Promise((resolve, reject) => {
    webAuth.authorize(
      {
        state: eventId,
        connection: 'email',
        nonce: 'nonce',
        login_hint: email,
      },
      (err: any, authResult: any) => (err ? reject(err) : resolve(authResult)),
    );
  });
};

interface Props {
  event: Event;
  isPreview?: boolean;
}

const EmailStage: React.FC<Props> = (props) => {
  const { event, isPreview } = props;

  const setAttendeeEmail = useAttendeeStore((state) => state.setAttendeeEmail);

  const [data, setData] = useState({
    email: '',
    confirmEmail: '',
    isTsAndCs: true,
  });

  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { type, name, value, checked } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (name === 'email') setAttendeeEmail(value.trim());

    setErrors({});
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isPreview) return;

    const validationErrors = await validateForm('email', data, isShowConfirm);

    // if errors found – set errors and stop here
    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    // if it's not confirm stage – change to it and stop here
    if (!isShowConfirm) {
      setIsShowConfirm(true);
      return;
    }

    // check in attendee
    const redirectUri = `${featureToggles.auth0 ? window.location.origin : ''}/complete-check-in?eventId=${event.eventId}`;

    if (featureToggles.auth0) {
      setIsProcessing(true);
      const webAuth = new auth0.WebAuth({
        domain: auth0Domain,
        clientID: auth0ClientID,
        responseType: 'token id_token',
        scope: 'openid profile email',
        redirectUri,
      });
      return authenticate(webAuth, event.eventId, data.email);
    }

    // redirect to check-in stage page
    else navigate(redirectUri);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="text-center font-bold">
        Tell us where to send your personalised video
      </div>

      <FloatingLabel
        label="Enter your email"
        name="email"
        value={data.email}
        onChange={handleChange}
        isInvalid={!!errors.email}
        errMsg={errors.email}
        required
      />

      {isShowConfirm ? (
        <FloatingLabel
          label="Confirm email"
          name="confirmEmail"
          value={data.confirmEmail}
          onChange={handleChange}
          isInvalid={!!errors.confirmEmail}
          errMsg={errors.confirmEmail}
          required
        />
      ) : (
        <div>
          <Checkbox
            id="ci-t-and-c-checkbox"
            label={getTsAndCsText(event.TsAndCsLink, event.organiserName)}
            name="isTsAndCs"
            checked={data.isTsAndCs}
            onChange={handleChange}
            isInvalid={!!errors.isTsAndCs}
          />

          <FormError errMsg={errors.isTsAndCs} />
        </div>
      )}

      <Button
        className="w-full"
        disabled={!isEmpty(errors)}
        isSubmitBtn
        isProcessing={isProcessing}
      >
        Check-in
      </Button>
    </Form>
  );
};

export default EmailStage;
