import React, { useMemo } from 'react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { getEventImg, getVideoBuilderLink } from '../../../Utils';
import { trackOpenProject } from '../../../analytics';

import aflEventThumbnail from '../../../assets/afl-event-thumbnail.jpeg';

import { EVENT_TYPE_DEMO } from '../../../constants';

import ProjectBlockOverlay from './projectBlockOverlay';

const ProjectBlock = (props) => {
  const { event } = props;

  const isDemo = event.type === EVENT_TYPE_DEMO;

  const navigate = useNavigate();

  const onClickEvent = () => {
    const pathname = window.location.pathname.replaceAll('/', '');
    trackOpenProject(pathname || 'home', event);
    navigate(getVideoBuilderLink(event));
  };

  const thumbnailClass =
    'w-[var(--event-block-width)] aspect-video rounded-md object-cover';

  const thumbnailImg = useMemo(() => {
    if (isDemo) return aflEventThumbnail;
    else if (event.banner) return getEventImg('thumbnail', event.banner);

    const numOfFiles = 20;
    const num = Math.ceil(
      new Date(event.created).getMilliseconds() / (1000 / numOfFiles),
    );

    return require(
      `/src/assets/projects-thumbnails/templify_fluid_dream_images_${num}.png`,
    );
  }, [event, isDemo]);

  return (
    <div className="group w-[var(--event-block-width)]">
      <div className="relative mb-1 sm:mb-2">
        <img
          className={`${thumbnailClass} cursor-pointer`}
          src={thumbnailImg}
          alt="Project Thumbnail"
          onClick={onClickEvent}
        />

        <ProjectBlockOverlay
          event={event}
          onClickEvent={onClickEvent}
          addClass={thumbnailClass}
        />
      </div>

      <Link
        to={getVideoBuilderLink(event)}
        className="block text-xs sm:text-sm font-bold no-underline text-true-black hover:text-primary-900 hover:opacity-100"
      >
        {event.name}
      </Link>

      {isDemo ? (
        <div className="text-xs italic">A sample event. Check me out.</div>
      ) : (
        <div className="flex justify-between flex-wrap gap-x-2 text-xs opacity-50">
          <div className="capitalize">{event.videoMode}</div>
          <div>{moment(event.modified).fromNow()}</div>
        </div>
      )}
    </div>
  );
};

export default ProjectBlock;
