import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { Button, Modal } from '../../../components/general';

import withRouter from '../../../withRouter';

const CaptionModal = (props) => {
  const {
    show,
    onHide,
    event,
    handleNativeShare,
    isCaptionOnly,
    isPreview,
    width,
  } = props;

  if (!event) return null;

  const caption = event.caption;
  const hashtag = event.hashtag;

  // copy text to clipboard
  const handleCopy = (copyText) => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        toast.success('Copied to clipboard!', { toastId: 'copy-success' });
      })
      .catch((error) => {
        console.error('Failed to copy:', error);
      });
  };

  // text of hashtag with #
  const hashtagText = hashtag
    .map((tag) => {
      return '#' + tag;
    })
    .join(' ');

  // text that user will copy
  const copyText = caption + '\n\n' + hashtagText;

  return (
    <Modal
      id="caption-modal"
      show={show}
      onHide={onHide}
      size="lg"
      previewSize={
        isPreview && { width: width / 0.75, height: (width / 0.75) * 2 }
      }
    >
      <Modal.Body className="text-center">
        <h4 className="m-0">Use pre-generated caption?</h4>

        <div>
          No need to get stuck on what to write. Simply use the generated
          hashtags and caption below to make your post stand out.
        </div>

        <div
          id="video-caption-field"
          className={classNames(
            'w-full h-32 flex flex-col px-3 py-1.5 items-start text-left text-sm bg-grey-100 whitespace-pre-wrap overflow-x-auto overflow-y-scroll rounded-md border border-grey-200 border-solid',
            { 'sm:h-20': !isPreview },
          )}
        >
          <div>{caption}</div>

          <div>
            {hashtag.map((ht, index) => (
              <React.Fragment key={index}>
                <a
                  href={`https://www.instagram.com/explore/tags/${ht}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  #{ht}
                </a>{' '}
              </React.Fragment>
            ))}
          </div>
        </div>

        <div
          className={classNames('flex flex-col gap-4', {
            'sm:flex-row': !isPreview,
          })}
        >
          <Button
            className={classNames('w-full', { 'sm:w-1/2': !isPreview })}
            onClick={() => {
              handleCopy(copyText);
              onHide();

              if (!isCaptionOnly) handleNativeShare('With caption');
            }}
          >
            Yes, Copy Caption to Clipboard
          </Button>

          <Button
            variant="pink"
            className={classNames('w-full', { 'sm:w-1/2': !isPreview })}
            onClick={() => {
              onHide();

              if (!isCaptionOnly) handleNativeShare('Without caption');
            }}
          >
            No{!isCaptionOnly && ', Continue Without Caption'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(CaptionModal);
