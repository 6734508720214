import React, { useMemo, useState, useEffect } from 'react';
import {
  ArrowsDownUp,
  Clock,
  ArrowUp,
  ArrowDown,
  SmileyMelting,
} from '@phosphor-icons/react';
import classNames from 'classnames';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';

import { isEmpty, sortByDate } from '../../../Utils';

import {
  trackProjectsTabChange,
  trackProjectsFilterChange,
  trackProjectsFilterClear,
  trackProjectsSortChange,
} from '../../../analytics';

import { Button, Dropdown } from '../../../components/general';
import {
  PageContainer,
  ContentHeader,
} from '../../../components/layout/content';

import { URL, EVENT_TYPE_DEMO } from '../../../constants';

import { useEventStore } from '../../../stores/event';
import { Event } from '../../../types';

import ProjectsContainer from '../components/projectsContainer';
import ProjectsTab from '../components/projectsTab';

const DEFAULT_FILTERS: { [key: string]: string } = { type: 'all', date: 'all' };

interface FilterSort {
  label: string;
  btnLabel?: string;
  value: string;
  icon?: React.ReactNode;
}

const Projects = () => {
  const queryParameters = new URLSearchParams(useLocation().search);
  const tabParam = queryParameters.get('tab') ?? 'all';
  const typeParam = queryParameters.get('type') ?? 'all';
  const dateParam = queryParameters.get('date') ?? 'all';
  const sortParam = queryParameters.get('sort') ?? 'newest';

  const userProjects: Event[] = useEventStore((state) => state.userEvents);
  const setEventId = useEventStore((state) => state.setEventId);

  const [pageTab, setPageTab] = useState(tabParam);
  const [filters, setFilters] = useState<{ [key: string]: string }>({
    type: typeParam,
    date: dateParam,
  });
  const [sort, setSort] = useState<string>(sortParam);

  const navigate = useNavigate();

  useEffect(() => {
    setEventId(null);
  }, [setEventId]);

  const tabs = ['all', 'recent', 'completed'];

  const typeFilter = useMemo(() => {
    const typeFilter: FilterSort[] = [
      { label: 'All types', btnLabel: 'Type', value: 'all' },
      { label: 'Videographer', value: 'videographer' },
    ];

    if (userProjects.some((p) => p.type === 'brand'))
      typeFilter.push({ label: 'Brand', value: 'brand' });

    if (userProjects.some((p) => p.attendeeReviewData?.isAttendeeReview))
      typeFilter.push({ label: 'Attendee Review', value: 'ar' });

    if (typeFilter.length > 2) return typeFilter;
    else return null;
  }, [userProjects]);

  const dateFilter: FilterSort[] = [
    { label: 'Any time', btnLabel: 'Date modified', value: 'all' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last week', value: 'week' },
    { label: 'Last month', value: 'month' },
    { label: 'Last quarter', value: 'quarter' },
    { label: 'Last year', value: 'year' },
  ];

  const sortArray: FilterSort[] = [
    { label: 'Newest modified', value: 'newest', icon: <Clock /> },
    { label: 'Oldest modified', value: 'oldest', icon: <Clock /> },
    { label: 'Alphabetical (A-Z)', value: 'a-z', icon: <ArrowUp /> },
    { label: 'Alphabetical (Z-A)', value: 'z-a', icon: <ArrowDown /> },
  ];

  const filteredProjects = useMemo(() => {
    let projects = userProjects.filter((p) => p.type !== EVENT_TYPE_DEMO);

    // type filter
    if (filters.type === 'videographer')
      projects = projects.filter(
        (p) =>
          p.type === filters.type && !p.attendeeReviewData?.isAttendeeReview,
      );
    else if (filters.type === 'brand')
      projects = projects.filter((p) => p.type === filters.type);
    else if (filters.type === 'ar')
      projects = projects.filter((p) => p.attendeeReviewData?.isAttendeeReview);

    // date filter
    const now = moment();
    const yesterday = moment().subtract(1, 'days');
    const weekAgo = moment().subtract(1, 'week');
    const monthAgo = moment().subtract(1, 'month');
    const quarterAgo = moment().subtract(1, 'quarter');
    const yearAgo = moment().subtract(1, 'year');

    if (filters.date === 'today')
      projects = projects.filter((p) => moment(p.modified).isSame(now, 'day'));
    else if (filters.date === 'yesterday')
      projects = projects.filter((p) =>
        moment(p.modified).isSame(yesterday, 'day'),
      );
    else if (filters.date === 'week')
      projects = projects.filter((p) =>
        moment(p.modified).isSameOrAfter(weekAgo, 'day'),
      );
    else if (filters.date === 'month')
      projects = projects.filter((p) =>
        moment(p.modified).isSameOrAfter(monthAgo, 'day'),
      );
    else if (filters.date === 'quarter')
      projects = projects.filter((p) =>
        moment(p.modified).isSameOrAfter(quarterAgo, 'day'),
      );
    else if (filters.date === 'year')
      projects = projects.filter((p) =>
        moment(p.modified).isSameOrAfter(yearAgo, 'day'),
      );

    // sort
    if (sort === 'newest') projects = sortByDate(projects, 'modified', true);
    else if (sort === 'oldest') projects = sortByDate(projects, 'modified');
    else if (sort === 'a-z')
      projects = projects.sort((a, b) => a.name.localeCompare(b.name));
    else if (sort === 'z-a')
      projects = projects.sort((a, b) => b.name.localeCompare(a.name));

    return projects;
  }, [userProjects, filters, sort]);

  const { recentProjects, completedProjects } = useMemo(() => {
    const recentProjects: Event[] = [];
    const completedProjects: Event[] = [];

    filteredProjects.forEach((project) => {
      if (project.stage === 'generation') completedProjects.push(project);
      else recentProjects.push(project);
    });

    return { recentProjects, completedProjects };
  }, [filteredProjects]);

  const hasFilters = useMemo(() => {
    return Object.keys(filters).some(
      (key) => filters[key] !== DEFAULT_FILTERS[key],
    );
  }, [filters]);

  const updateSearchParam = (key: string, value: string) => {
    queryParameters.set(key, value);
    navigate(`${URL.DASH_PROJECTS}?${queryParameters.toString()}`, {
      replace: true,
    });
  };

  const updateFilter = (key: string, value: string) => {
    let filterTemp = { ...filters };
    filterTemp[key] = value;
    setFilters(filterTemp);
    updateSearchParam(key, value);
    trackProjectsFilterChange(key, value);
  };

  const clearFilters = () => {
    setFilters(DEFAULT_FILTERS);

    let url = `${URL.DASH_PROJECTS}?tab=${pageTab}&sort=${sort}`;
    Object.keys(DEFAULT_FILTERS).forEach(
      (key) => (url += `&${key}=${DEFAULT_FILTERS[key]}`),
    );
    navigate(url);

    trackProjectsFilterClear();
  };

  const NoResultsBlock = (
    <div className="w-full flex flex-col items-center justify-center py-10 gap-4 text-center">
      <SmileyMelting
        weight="fill"
        className="fill-[url('#icon-primary-gradient')]"
        size={100}
      />

      <div className="text-lg font-bold">Oops! We couldn’t find anything</div>

      <div className="text-sm">
        No results match your selected filters. Try a different combination or
        clear all filters to start fresh.
      </div>
    </div>
  );

  const renderFilterBtn = (
    filterArray: FilterSort[],
    filterKey: string,
    menuHeading: string,
  ) => {
    const selectedFilter = filterArray.find(
      (item) => item.value === filters[filterKey],
    );
    const btnLabel = selectedFilter?.btnLabel ?? selectedFilter?.label ?? '';

    return (
      <Dropdown
        btnData={{
          label: btnLabel,
          variant: 'grey-outline',
          withCaret: true,
          className: classNames('text-sm', {
            'bg-primary-50 hover:bg-primary-50 text-primary-900 border-primary-900':
              filters[filterKey] !== 'all',
          }),
        }}
        menuHeading={menuHeading}
        menuItems={filterArray.map((item) => {
          return {
            label: item.label,
            onClick: () => updateFilter(filterKey, item.value),
            isCurrent: item.value === filters[filterKey],
            show: true,
            closeOnClick: true,
            className: 'w-60',
          };
        })}
        position="left"
      />
    );
  };

  const renderContent = () => {
    if (pageTab === 'recent' && !isEmpty(recentProjects))
      return (
        <ProjectsContainer
          heading="Recent projects"
          events={recentProjects}
          hideHeading
        />
      );
    else if (pageTab === 'completed' && !isEmpty(completedProjects))
      return (
        <ProjectsContainer
          heading="Completed projects"
          events={completedProjects}
          hideHeading
        />
      );
    else if (pageTab === 'all' && !isEmpty(filteredProjects))
      return (
        <React.Fragment>
          <ProjectsContainer
            heading="Recent projects"
            events={recentProjects}
            isCollapse
          />

          <ProjectsContainer
            heading="Completed projects"
            events={completedProjects}
            isCollapse
          />
        </React.Fragment>
      );
    else return NoResultsBlock;
  };

  return (
    <PageContainer title="Projects" addClass="gap-8">
      <ContentHeader title="Projects" />

      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
        <div className="flex items-center gap-4">
          {typeFilter && renderFilterBtn(typeFilter, 'type', 'Type')}

          {renderFilterBtn(dateFilter, 'date', 'Date modified')}

          {hasFilters && (
            <Button
              onClick={clearFilters}
              variant="link"
              className="no-underline"
            >
              Clear all
            </Button>
          )}
        </div>

        <div className="flex items-center gap-4">
          <Dropdown
            btnData={{
              label:
                sortArray.find((i) => i.value === sort)?.label ?? 'Sort by',
              variant: 'grey-outline',
              icon: <ArrowsDownUp size="1.25rem" />,
              withCaret: true,
              className: 'text-sm',
            }}
            menuHeading="Sort by"
            menuItems={sortArray.map((item) => {
              return {
                label: item.label,
                onClick: () => {
                  setSort(item.value);
                  updateSearchParam('sort', item.value);
                  trackProjectsSortChange(item.value);
                },
                isCurrent: item.value === sort,
                icon: item.icon,
                show: true,
                closeOnClick: true,
                className: 'w-60',
              };
            })}
            position="left"
          />
        </div>
      </div>

      <div className="flex gap-4">
        {tabs.map((tab) => (
          <ProjectsTab
            label={tab}
            onClick={() => {
              setPageTab(tab);
              updateSearchParam('tab', tab);
              trackProjectsTabChange(tab);
            }}
            isCurrent={pageTab === tab}
          />
        ))}
      </div>

      {renderContent()}
    </PageContainer>
  );
};

export default Projects;
